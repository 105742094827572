import styled from 'styled-components'

import { IoSearch } from 'react-icons/io5'
import React from 'react'

const InputContainer = styled.label`
	background-color: var(--colors-ui-base);
	padding: 1rem 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;

	border-radius: var(--radii);
	box-shadow: var(--shadow);
	width: 100%;
	margin-bottom: 1rem;

	@media(min-width: 767px) {
		margin-bottom: 0;
		padding: 1rem 2rem;
		width: 280px;
		gap: 2rem;
	}
`;
const Input = styled.input.attrs({
	type: 'search',
	placeholder: 'Search for a country ...'
})`
	background: none;
	border: none;
	outline: none;
	color: var(--colors-text)
`;

export const Search = ({search, setSearch}) => {
	return (
		<InputContainer>
			<IoSearch/>
			<Input onChange={e => setSearch(e.target.value)} value={search}/>
		</InputContainer>
	)
}
