import React, { useState, useEffect } from 'react'
import { IoMoon, IoMoonOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Container } from './Container'

const HeaderEl = styled.header`
	box-shadow: var(--shadow);
	background-color: (--colors-ui-base);

`;
const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 2rem 0;
`;
const Title = styled(Link).attrs({
	to: '/',
})`
	color: var(--colors-text);
	font-size: var(--fs-sm);
	text-decoration: none;
	font-weight: var(--fw-bold);
`;
const ModeSwitcher = styled.div`
	color: var(--colors-text);
	font-size: var(--fs-sm);
	cursor: pointer;
	text-transform: capitalize;
`;

export const Header = () => {
	const localTheme = localStorage.getItem('theme');
	const [theme, setTheme] = useState(localTheme ? localTheme : 'light');

	const toggleTheme = () => {
		setTheme(theme === 'light' ? 'dark' : 'light')
		localStorage.removeItem('theme')
		localStorage.setItem('theme', theme === 'light' ? 'dark' : 'light')
		console.log(localStorage.getItem('theme'))
	}

	useEffect(() => {
		if(localTheme) {
			document.body.setAttribute('data-theme', localTheme)
		} else {
			document.body.setAttribute('data-theme', theme)
		}
		// eslint-disable-next-line
	}, [theme])

	return (
		<HeaderEl>
			<Container>
				<Wrapper>
					<Title>Where is the world?</Title>
					<ModeSwitcher onClick={toggleTheme}>
						{	theme === 'light'
							? <IoMoonOutline size="14px"/>
							:	<IoMoon size="14px"/>
						}
						{' '}
						<span style={{marginLeft: '0.5rem'}}>{theme === 'light' ? 'dark' : 'light'} Theme</span>
					</ModeSwitcher>
				</Wrapper>
			</Container>
		</HeaderEl>
	)
}
