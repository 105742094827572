import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import axios from 'axios';
import { filterByCode } from '../config';

const Wrapper = styled.section`
	margin-top: 3rem;
	width:  100%;
	display: grid;
	grid-template-columns: 100%;
	gap: 2rem;

	@media(min-width: 767px) {
		grid-template-columns: minmax(100px, 400px) 1fr;
		align-items: center;
		gap: 5rem;
	}

	@media(min-width: 1024px) {
		grid-template-columns: minmax(400px, 600px) 1fr;
	}
`;

const InfoImage = styled.img`
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
	box-shadow: var(--shadow);
`;

const InfoTitle = styled.h1`
	margin: 0;
	font-weight: var(--fw-normal);
`;

const ListGroup = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;

	@media(min-width: 1024px) {
		flex-direction: row;
		gap: 5rem;
	}
`;

const List = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
`;

const ListItem = styled.li`
	line-height: 1.8;

	& > b {
		font-weight: var(--fw-bold);
	}
`;

const Meta = styled.div`
	margin-top: 3rem;
	display: flex;
	gap: 1.5rem;
	flex-direction: column;
	align-items: flex-start;
	& > b {
		font-weight: var(--fw-bold);
	}

	@media (min-width: 767px) {
		flex-direction: row;
		align-items: center;
	}
`;

const TagGroup = styled.div`
	display: flex;
	gap: 1rem;
	flex-wrap: wrap;
`;

const Tag = styled.span`
	padding: 0 1rem;
	background-color: var(--colors-ui-base);
	box-shadow: var(--shadow);
	line-height: 1.5;
	cursor: pointer;
`;


const InfoCountry = (props) => {
	const {
		name,
		nativeName,
		flag,
		capital,
		population,
		region,
		subregion,
		topLevelDomain,
		currencies = [],
		languages = [],
		borders = [],
		push
	} = props

	const [neighbors, setNeighbors] = useState([])

	useEffect(() => {
		axios.get(filterByCode(borders)).then(
		({data}) => setNeighbors(data.map(c => c.name))
		)
	}, [borders])

	return (
		<Wrapper>
			<InfoImage src={flag} alt={name}/>

			<div>
				<InfoTitle>{name}</InfoTitle>
				<ListGroup>
					<List>
						<ListItem>
							<b>Native name:</b> {nativeName}
						</ListItem>
						<ListItem>
							<b>Population:</b> {population.toLocaleString()}
						</ListItem>
						<ListItem>
							<b>Region:</b> {region}
						</ListItem>
						<ListItem>
							<b>Sub region:</b> {subregion}
						</ListItem>
						<ListItem>
							<b>Capital:</b> {capital}
						</ListItem>

					</List>
					<List>
						<ListItem>
							<b>Top Level Domain:</b> {topLevelDomain.map((d, index) =>
								<>
										{index + 1 === topLevelDomain.length ? <span key={d}> {d}</span> : <span key={d}> {d},</span>}
								</>
							)}
						</ListItem>
						<ListItem>
							<b>Currency:</b> {currencies.map((c, index) =>
								<>
										{index + 1 === currencies.length ? <span key={c.code}> {c.name}</span> : <span key={c.code}> {c.name},</span>}
								</>
							)}
						</ListItem>
						<ListItem>
							<b>Languages:</b> {languages.map((l, index) =>
									<>
										{index + 1 === languages.length ? <span key={l.name}> {l.name}</span> : <span key={l.name}> {l.name},</span>}
									</>
								)}
						</ListItem>
					</List>
				</ListGroup>
				<Meta>
					<b>Border Countries:</b>
					{!borders.length ? (
						<span>There is no border countries</span>
					)
					: (
						<TagGroup>
							{neighbors.map(b => (<Tag onClick={() => push(`/country/${b}`)} key={b}>{b}</Tag>))}
						</TagGroup>
					)
				}
				</Meta>
			</div>
		</Wrapper>
	)
}

export default InfoCountry