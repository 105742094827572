import React, {useState, useEffect} from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios'
import {IoArrowBack} from 'react-icons/io5'
import { searchByCountry } from '../config'
import { ButtonDetails } from '../components/ButtonDetails'
import InfoCountry from '../components/InfoCountry'


export const Details = () => {
	const {name} = useParams()
	const navigate = useNavigate()
	const [country, setCountry] = useState(null)

	console.log(country)

	useEffect(() => {
		axios.get(searchByCountry(name)).then(
			({data}) => setCountry(data[0])
		)
	}, [name])

	return (
		<div>
			<ButtonDetails onClick={() => navigate(-1)}><IoArrowBack/> Back</ButtonDetails>
			{country && <InfoCountry push={navigate} {...country} />}
		</div>
	)
}
