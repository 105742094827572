import axios from 'axios'
import {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { ALL_COUNTRIES } from "../config";
import { List } from "../components/List";
import { Card } from "../components/Card";
import { Controls } from "../components/Controls";

export const HomePage = ({countries, setCountries}) => {
	const [filterCountry, setFilterCountry] = useState(countries)
	const [loading, setLoading] = useState(false)

	const navigate = useNavigate();

	const handleSearch = (search, region) => {
		let data = [...countries]

		if(region) {
			data = data.filter(c => c.region.includes(region))
		}

		if (search) {
			data = data.filter(c => c.name.toLowerCase().includes(search.toLowerCase()))
		}

		setFilterCountry(data)
		setLoading(true)
	}


	useEffect(() => {
		if(!countries.length) {
			axios.get(ALL_COUNTRIES).then(
				({data}) => {
					setCountries(data)
				})
		}
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		handleSearch();
		// eslint-disable-next-line
	}, [countries])

	return (
		<>
			{loading
				?
				<>
					<Controls onSearch={handleSearch} />
					<List>
						{
							!filterCountry.length
							? <div>Nothing found</div>

							:
								filterCountry.map(el => {
									const countryInfo = {
										img: el.flags.png,
										name: el.name,
										info: [
											{
												title: 'Pupulation',
												description: el.population.toLocaleString()
											},
											{
												title: 'Region',
												description: el.region
											},
											{
												title: 'Capital',
												description: el.capital
											},
										],
									};

									return (
										<Card key={el.name} onClick={() => navigate(`/country/${el.name}`)}  {...countryInfo}/>
									)
								})
						}
					</List>
				</>
				: <h1>Loading...</h1>
			}
		</>
	)
}
