import { Header } from "./components/Header";
import { Main } from "./components/Main";
import {Routes, Route} from 'react-router-dom'
import {HomePage} from './pages/HomePage'
import {NotFound} from './pages/NotFound'
import {Details} from './pages/Details'
import {useState} from 'react'


function App() {
	const [countries, setCountries] = useState([]);

  return (
    <div className="App">
			<Header/>
			<Main>
				<Routes>
					<Route element={<HomePage countries={countries} setCountries={setCountries}/>} path={'/'} exact/>
					<Route element={<Details/>} path={'/country/:name'} exact/>
					<Route element={<NotFound/>} path={'*'}/>
				</Routes>
			</Main>
    </div>
  );
}

export default App;
